<template>
  <div class="table-box">
    <el-table :data="list"
              border
              class="personal_table"
              style="width: 100%">
      <el-table-column prop="nick_name"
                       align="center"
                       label="申请人">
      </el-table-column>
      <el-table-column prop="created_at"
                       align="center"
                       label="申请时间">
      </el-table-column>
      <el-table-column prop="cert_status"
                       align="center"
                       label="状态">
        <template #default="scope">
          <span class="table_status"
                :class="transStatus[scope.row.cert_status] === '待审核' ?'yellow':transStatus[scope.row.cert_status] === '审核通过'?'green':'red'">{{transStatus[scope.row.cert_status]}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       prop="operation"
                       label="操作">
        <template #default="scope">
          <span class="detail_btn"
                @click="detailclick(scope.row)">查看</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-sizes="[10, 30, 50, 100]"
                   :page-size="30"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
    <Detail :showModal="showModal"
            :detailData="detailData"
            :tabcurrent='tabcurrent'
            @closeDetail="closeDetail"></Detail>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, toRefs, reactive, getCurrentInstance } from 'vue'
const Detail = defineAsyncComponent(() => import('./detail.vue'))

export default defineComponent({
  emits: ['handleSizeChange', 'handleCurrentChange', 'getAuditList'],
  components: {
    Detail
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    tabcurrent: {
      type: String,
      default: '0'
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  setup (props, context) {
    const data = reactive({ // 定义变量
      showModal: false,
      detailData: {},
      transStatus: {
        0: '待审核',
        1: '审核通过',
        2: '审核驳回'
      },
      newList: props.list
    })
    const { proxy } = getCurrentInstance()
    const handleSizeChange = (val) => {
      context.emit('handleSizeChange', val)
    }
    const handleCurrentChange = (val) => {
      context.emit('handleCurrentChange', val)
    }
    const detailclick = async (row) => {
      try {
        const sd = await proxy.$api.getAuditDetail(
          {
            id: row.id
          })
        data.detailData = sd.data
        data.showModal = true
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }
    const closeDetail = (val) => {
      data.showModal = false
      context.emit('getAuditList')
    }
    return {
      ...toRefs(data),
      handleSizeChange,
      handleCurrentChange,
      detailclick,
      closeDetail
    }
  }
})
</script>

<style scoped lang='scss'>
.table-box::v-deep(.el-table__header-wrapper) {
  height: 60px;
  line-height: 30px;
  margin-bottom: -5px;
  th {
    font-weight: normal;
    background: #fafafa;
    color: #333;
  }
}
.table-box::v-deep(.el-table__row) {
  height: 50px;
  line-height: 50px;
}
.personal_table {
  height: calc(100vh - 280px);
  overflow: auto;
}
.detail_btn {
  color: #1d84fa;
  cursor: pointer;
}
.yellow {
  color: #fc832c;
}
.green {
  color: #01d356;
}
.red {
  color: #f12200;
}
.personal_table::before {
  z-index: 0 !important;
}
</style>
